/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { Fetish } from '@/store/fetish/types';

const namespace: string = 'fetish';

export default class FetishCategoriesPage extends Vue {
	@State('fetish') fetish: Fetish | undefined;
	@Mutation('setFetishCategory', {namespace}) setFetishCategory: any;
	@Mutation('setSelectedCategory', {namespace}) setSelectedCategory: any;
	@Getter('getFetishCategory', {namespace}) getFetishCategory: any;
	@Action('fetchFetishCategories', {namespace}) fetchFetishCategories: any;

	fetishCategoryChoise(category: any) {
		console.log(typeof category);
		this.setSelectedCategory(category);
		this.$router.push({ name: 'fetish-model-choice' }).then(r => r);
	}
	mounted() {
		this.fetchFetishCategories();
		// console.log(this.getFetishCategory);
	}
}
